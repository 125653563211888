import React, { ReactNode, useEffect } from "react";
import { useNavigation, useUser } from "common/hooks";
import usePreMutation from "common/hooks/usePreMutation";

interface SignedOutContainerProps {
  children: ReactNode;
}

/* 유저 정보가 있을 때 로그인, 회원가입 등의 페이지 접근을 막는다. */
const SignedOutContainer = ({ children }: SignedOutContainerProps) => {
  const { isSignedIn } = useUser();
  const { navigate } = useNavigation();
  // const { preMutation } = usePreMutation();

  useEffect(() => {
    if (isSignedIn) {
      navigate("/");
    }
  }, [isSignedIn]);

  // useEffect(() => {
  //   preMutation();
  // }, []);
  return <>{children}</>;
};

export default SignedOutContainer;
