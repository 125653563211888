import { useBackgroundRefreshTokenQuery } from "common/queries/authQuery";
import { getCookie } from "common/utils/cookieUtils";

const usePreMutation = () => {
  const { mutateAsync } = useBackgroundRefreshTokenQuery();

  const mutationWrapper = (callback: () => void) => {
    const accessToken = getCookie("scm_store_at");
    if (accessToken) {
      // accessToken 존재 시, 문제없이 실행
      callback();
      return;
    }
    // accessToken 없을 시, refreshToken 먼저 실행
    mutateAsync()
      .then(callback) // callback 실행
      .catch(() => {
        // refreshToken 실패
        alert("에러가 발생했습니다. 계속되면 관리자에게 문의해주세요.");
        location.href = "/";
      });
  };

  const preMutation = async () => {
    const accessToken = getCookie("scm_store_at");
    if (accessToken) {
      // accessToken 존재 시, 문제없이 실행
      return;
    }
    // accessToken 없을 시, refreshToken 먼저 실행
    await mutateAsync();
  };

  return { mutationWrapper, preMutation };
};

export default usePreMutation;
