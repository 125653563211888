import { Button } from "@mui/material";
import { useModal } from "common/hooks";
import usePreMutation from "common/hooks/usePreMutation";
import { useSignOutQuery } from "common/queries/authQuery";
import React, { useCallback } from "react";

const SignOutConfirmModal = () => {
  const { modal } = useModal();
  const { querySignOut } = useSignOutQuery();
  const { mutationWrapper } = usePreMutation();

  const handleSignOutConfirmButtonClick = useCallback(() => {
    mutationWrapper(() => {
      querySignOut();
      modal.close();
    });
  }, []);
  return (
    <div id="sign-out-modal">
      <div className="confirm-text">로그아웃하시겠습니까?</div>
      <div className="sign-out-button-group">
        <Button
          className="sign-out-button"
          variant="contained"
          onClick={handleSignOutConfirmButtonClick}
        >
          확인
        </Button>
        <Button
          className="sign-out-button"
          variant="contained"
          onClick={modal.close}
        >
          취소
        </Button>
      </div>
    </div>
  );
};

export default SignOutConfirmModal;
