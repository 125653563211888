const URLs = {
  Main: "/",
  SignIn: "/sign-in",
  Test: "/test",
  MyPage: "/my-page",
  // 도서주문 - Orders
  TotalSearch: "/order/total-search",
  FileOrder: "/order/file",
  RecommendedOrder: "/order/recommended",
  SearchOrder: "/order/search",
  NewBookOrder: "/order/new-book",
  BestBookOrder: "/order/best-book",
  DirectDeliveryOrderSearch: "/order/direct-delivery-search",
  DirectDeliveryOrderFile: "/order/direct-delivery-file",
  CartView: "/order/cart-view",
  OrderInquiry: "/order-inquiry",
  // 입점몰 센터
  MallCenter: "/mall-center",
  // 납품 - Delivery
  DeliveryOrderStatus: "/delivery/order-status",
  DeliveryDealStatus: "/delivery/deal-status",
  DeliveryProgress: "/delivery/progress",
  // 주문현황 - History(내역)
  OrderHistoryView: "/order-history-view",
  OrderHistoryByDailyView: "/order-history-by-daily-view",
  DirectDeliveryOrderHistoryView: "/direct-delivery-order-history-view",
  OutcomingHistoryView: "/outcoming-history-view",
  RefundHistoryView: "/refund-history-view",
  ReturnRequestView: "/return-request-view",
  ReturnRequestForm: "/return-request-form",
  ReturnRequestFormForPG: "/return-request-form-for-pg",
  // 거래관리 - Deals
  DealsLedgerView: "/deals-ledger-view",
  BooksLedgerView: "/books-ledger-view",
  BookStoreInfo: "/book-store-info",
  // 서점 지원 - Support
  Notice: "/support/notice",
  NoticeDetail: "/support/notice-detail",
  OneToOne: "/support/one-to-one",
  OneToOneDetail: "/support/one-to-one-detail",
  OneToOneInquiry: "/support/one-to-one-inquiry",
  ReturnRequire: "/support/return-require",
  ManageCustomer: "/support/manage-customer",
  ManageIncome: "/support/manage-income",
  PaymentSuccess: "/payment-success",
  PaymentFailure: "/payment-failure",
} as const;

export default URLs;
