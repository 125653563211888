import { useCallback, useEffect, useState } from "react";

const useSearchFilter = () => {
  const [searchParams, setSearchParams] = useState<Record<string, unknown>>({});

  useEffect(() => {
    if (searchParams?.["reset"] == "true") {
      setSearchParams({});
    }
  }, [searchParams]);

  const handleSearchParamsChange = useCallback(
    (target: string, value: string | File) => {
      setSearchParams((prev) => ({ ...prev, [target]: value }));
    },
    [],
  );

  return { searchParams, handleSearchParamsChange };
};

export default useSearchFilter;
