import React, { ReactNode } from "react";
import Loading from "../LoadingSpinner";
import { useLoadingStore } from "stores/loadingStore";

interface LoadingContainerProps {
  children: ReactNode;
}

const LoadingContainer = ({ children }: LoadingContainerProps) => {
  const isLoading = useLoadingStore();

  return (
    <>
      {children}
      {isLoading && <Loading />}
    </>
  );
};

export default React.memo(LoadingContainer);
