import { useCallback } from "react";
import { setErrorStore, useErrorStore } from "stores/errorStore";

const useError = () => {
  const isError = useErrorStore();

  const setError = useCallback(() => {
    setErrorStore(true);
  }, []);

  return { isError, setError };
};

export default useError;
