import React from "react";
import { createStore } from "@methodjs/store";

interface DisplayModalState {
  open: boolean;
  ModalComponent: JSX.Element;
  preventBackgroundClickClose: boolean;
}

export const DefaultModal = () => {
  return <></>;
};

export const initDisplayModalState = (): DisplayModalState => ({
  open: false,
  ModalComponent: <DefaultModal />,
  preventBackgroundClickClose: false,
});

const [useDisplayModalStore, setDisplayModalStore, getDisplayModalStore] =
  createStore(initDisplayModalState);

export { useDisplayModalStore, setDisplayModalStore, getDisplayModalStore };
