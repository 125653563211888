import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // suspense: true,
      staleTime: 1000 * 60 * 5, // 5 mins
      cacheTime: 1000 * 60 * 15, // 15 mins
      retry: 1,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error, query) => {
        query.cancel();
        if (axios.isAxiosError(error))
          // 서버 에러일 경우 500 이상의 응답코드에서만 상위 errorBoundary로 throw한다.
          return (error?.response?.status as number) >= 500;
        // 기타 에러일 경우 상위 errorBoundary로 throw한다.
        else return true;
      },
    },
    mutations: {
      useErrorBoundary: (error) => {
        if (axios.isAxiosError(error))
          // axios 에러일 경우 500 이상의 응답코드에서 errorBoundary로 throw한다.
          return (error?.response?.status as number) >= 500;
        // 기타 에러일 경우 상위 errorBoundary로 throw한다.
        else return true;
      },
    },
  },
});
