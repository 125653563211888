import React from "react";
import { createStore } from "@methodjs/store";

interface PrintStoreState {
  PrintElement: () => JSX.Element;
  handlePrintButtonClick: () => void;
}

export const initPrintStoreState = (): PrintStoreState => ({
  PrintElement: () => <></>,
  handlePrintButtonClick: () => {
    /* 
    기본 상태: 아무것도 하지 않는 함수
    함수의 실제 동작 부분은 ref 전달 때문에 PrintContainer 컴포넌트에 구현되어있다.
    */
  },
});

const [usePrintStore, setPrintStore, getPrintStore] =
  createStore(initPrintStoreState);

export { usePrintStore, setPrintStore, getPrintStore };
