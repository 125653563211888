import { Checkbox } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useUser from "./useUser";

const useBranchSelection = (isHeadCodeOverride = false) => {
  /* 
  전역 branch를 사용하는 곳은 Header 뿐이므로
  공용으로 사용할 때는 global값을 넣어주지 않아도 되게, 
  파라미터 미전달시 false가 되도록 설정. 
  */
  const { user } = useUser();

  //전역 상태
  const { branchCode, headCode } = user;

  // 체크 컴포넌트는 대표 코드인지를 판단할 거고, 기본값은 false(지점 코드)
  const [isHeadCode, setIsHeadCode] = useState(false);

  let selectedCode = isHeadCode ? headCode : branchCode;

  if (isHeadCodeOverride) selectedCode = headCode;

  const CodeCheckboxComponent = useCallback(
    ({
      setSearchParams,
      paramName,
      branchIntegrationYn,
    }: {
      className?: string;
      setSearchParams?: (target: string, value: string) => void;
      paramName?: string;
      branchIntegrationYn?: boolean;
    }) => {
      // isHeadCode 값에 따라 선택된 코드를 setSearchParams에 넣어준다.
      const searchParam = !branchIntegrationYn
        ? selectedCode
        : isHeadCode.toString();
      useEffect(() => {
        setSearchParams?.(paramName as string, searchParam);
      }, [isHeadCode, isHeadCodeOverride]);

      const handleCheckboxClick = (
        event: React.ChangeEvent<HTMLInputElement>,
      ) => setIsHeadCode(event.target.checked);

      return <Checkbox checked={isHeadCode} onChange={handleCheckboxClick} />;
    },
    [user, global, isHeadCode, isHeadCodeOverride],
  );
  return {
    // global 값에 따라 select된 브랜치를 다르게 리턴.
    selectedCode,
    CodeCheckboxComponent,
  };
};

export default useBranchSelection;
