import type { Menu } from "types/menu";

export const initialMenuState: Menu[] = [
  {
    title: "도서주문",
    childMenus: [
      {
        title: "통합검색",
      },
      {
        title: "도서검색주문",
      },
      {
        title: "파일등록주문",
      },
      {
        title: "추천도서주문",
      },
      {
        title: "신간목록주문",
      },
      // {
      //   title: "베스트목록주문",
      // },
      {
        title: "직배주문",
      },
      {
        title: "직배주문(엑셀주문)",
      },
      {
        title: "장바구니",
      },
    ],
  },
  // {
  //   title: "모두같이센터",
  //   // url: "/mall-center",
  //   childMenus: [
  //     {
  //       title: "모두몰센터",
  //     },
  //     // {
  //     //   title: "주문내역",
  //     // },
  //     // {
  //     //   title: "출고내역",
  //     // },
  //     // {
  //     //   title: "반품내역",
  //     // },
  //   ],
  // },
  {
    title: "납품",
    childMenus: [
      {
        title: "납품주문내역",
      },
      {
        title: "납품거래내역",
      },
      {
        title: "납품진행조회",
      },
    ],
  },
  {
    title: "주문현황",
    childMenus: [
      {
        title: "주문내역조회",
      },
      {
        title: "일별주문내역조회",
      },
      {
        title: "직배주문내역조회",
      },
      {
        title: "출고내역조회",
      },
      {
        title: "반품내역조회",
      },
      {
        title: "온라인반품 조회(신청)",
      },
    ],
  },
  {
    title: "거래관리",
    childMenus: [
      {
        title: "거래원장조회",
      },
      {
        title: "도서원장조회",
      },
      {
        title: "서점정보",
      },
    ],
  },
  {
    title: "서점 지원",
    childMenus: [
      {
        title: "공지사항",
      },
      {
        title: "1:1 문의",
      },
      {
        title: "반품요청공지",
      },
      // {
      //   title: "고객관리",
      // },
      // {
      //   title: "매출관리",
      // },
    ],
  },
];
