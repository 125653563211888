import React, { CSSProperties } from "react";
/*  test 페이지의 컴포넌트와 크기를 맞춰서 로딩이나 스켈레톤 UI를 제공하면
    데이터가 불러와짐에 따라 화면 크기가 변하는 사용자 경험을 없앨 수 있다.
    현재는 데이터를 불러오거나 보여주지 않으므로 fallback UI와 크기를 맞췄다.
*/
interface SkeletonLoadingProps {
  style?: CSSProperties;
}

const SkeletonLoading = ({ style }: SkeletonLoadingProps) => {
  return (
    <div
      // className="skeleton-container"
      style={style}
    >
      {/* <div className="loading-spinner"></div> */}
    </div>
  );
};

export default React.memo(SkeletonLoading, () => true);
