import { useNavigation, useUser } from "common/hooks";
import usePreMutation from "common/hooks/usePreMutation";
import { useBackgroundRefreshTokenQuery } from "common/queries/authQuery";
import { useUserInfoQuery } from "common/queries/userQuery";
import { deleteCookie, getCookie } from "common/utils/cookieUtils";
import React, { ReactNode, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface SignedInContainerProps {
  children: ReactNode;
}

const SignedInContainer = ({ children }: SignedInContainerProps) => {
  useUserInfoQuery();
  const { navigate } = useNavigation();
  const { pathname } = useLocation();
  const { user } = useUser();
  useBackgroundRefreshTokenQuery();

  const { preMutation } = usePreMutation();

  // 입점몰 관리자 페이지 새 탭으로 열기
  const handelAdminPageLink = useCallback(async () => {
    location.href =
      user.mallAdmSignInUrl +
      "/?userId=" +
      user.meclass_user_id +
      "&accessKey=" +
      getCookie("scm_store_at");
  }, []);

  useEffect(() => {
    const scm_store_at = getCookie("scm_store_at");
    if (!scm_store_at && !pathname.includes("sign-in")) {
      navigate("/sign-in");
    }

    if (
      scm_store_at &&
      user.initSecretKeyYn === "Y" &&
      !pathname.includes("my-page")
    ) {
      alert(
        "회원님께서는 임시발급된 비밀번호를 사용중이십니다.\n\n" +
          "비밀번호를 변경하지 않으시면 악의적 사용자에 의한 개인정보 도용피해를 입으실 수 있습니다.\n\n" +
          "비밀번호 변경절차를 거치신 후 서비스 이용바랍니다.",
      );
      navigate("/my-page");
    }

    // 입점몰 계정인 경우 아래의 로직 실행
    if (user.initSecretKeyYn === "N" && user.meclass_gb === "F") {
      deleteCookie("scm_store_rt");

      handelAdminPageLink();

      return;
    }
  }, [pathname]);

  return <>{children}</>;
};

export default SignedInContainer;
