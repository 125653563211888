import { createStore } from "@methodjs/store";
import type { UserInfo } from "types/user";

interface UserState {
  isSignedIn: boolean;
  user: UserInfo;
}

export const initUserState = (): UserState => ({
  isSignedIn: false,
  user: {
    modSecretKey: "",
    userName: "",
    cont_gb: "01",
    cont_gbnm: "특약",
    banphan_amt: 0,
    han_amt: 0,
    misu_amt: 0,
    juing_amt: 0,
    branchCode: "",
    headCode: "",
    shipFreeAmt: 0,
    vbankLimitAmt: 0,
    shipAmt: 0,
    cardLimitAmt: 0,
    accounts: [],
    jpcust_nm: "",
    mid: "",
    merchantKey: "",
    meclass_gb: "",
    meclass_user_id: "",
    mallAdmSignInUrl: "",
    initSecretKeyYn: "",
    cust_gb: "",
    besong_gb: "",
  },
});

const [useUserStore, setUserStore, getUserStore] = createStore(initUserState);

export { useUserStore, setUserStore, getUserStore };
