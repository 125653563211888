import { Button, Input, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "config/routeConfig";
import { useModal, useNavigation, useUser, useError } from "common/hooks";
import usePreMutation from "common/hooks/usePreMutation";
import SignOutConfirmModal from "../Modals/SignOutConfirmModal";
import ImageContainer from "../Containers/ImageContainer";
import KPCLogo from "assets/imgs/kpc-logo.png";
import cartIcon from "assets/imgs/cart_icon_black.svg";
import { initialMenuState } from "common/constants/initialMenuState";
import { formatNumberWithComma } from "common/utils/commonUtils";
import { useTotalSearchMutation } from "common/queries/bookQuery";
import useBranchSelection from "common/hooks/useBranchSelection";
import { deleteCookie, getCookie } from "common/utils/cookieUtils";
import { ChildMenu } from "types/menu";

const SignButton = () => {
  const { isSignedIn } = useUser();
  const { navigate } = useNavigation();
  const { modal } = useModal();
  const { pathname } = useLocation();

  if (isSignedIn) {
    return (
      <Button
        className="header-button"
        variant="text"
        onClick={() => modal.open(<SignOutConfirmModal />)}
      >
        로그아웃
      </Button>
    );
  } else {
    return (
      <Button
        className="header-button"
        variant="text"
        onClick={() =>
          navigate("/sign-in", {
            state: {
              callbackURL: pathname,
            },
          })
        }
      >
        로그인
      </Button>
    );
  }
};

const Header = () => {
  const { navigate } = useNavigation();
  const location = useLocation();
  const [isHeaderNeeded, setIsHeaderNeeded] = useState(true);
  const [amt, setAmt] = useState({ usedAmt: 0, ableAmt: 0 });
  const [searchKeyword, setSearchKeyword] = useState("");
  const { isError } = useError();
  const { isSignedIn, user } = useUser();
  const { preMutation } = usePreMutation();
  const { selectedCode } = useBranchSelection();
  const [
    menuData,
    // setMenuData
  ] = useState(initialMenuState);

  // 통합검색
  const { data: totalSearchData, handleMutation: totalSearchBookMutation } =
    useTotalSearchMutation();

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname === route.path) {
        setIsHeaderNeeded(route.requireHeader);
      }
    });
  }, [location]);

  const handleLogoButtonClick = useCallback(async () => {
    await preMutation();
    navigate("/");
  }, []);

  const [menuWrapperClassName, setMenuWrapperClassName] =
    useState("menu-wrapper");

  // 잔액(사용금액), 주문가능금액
  useEffect(() => {
    // 사용금액(미수금액)
    const used_amt = user.misu_amt + user.juing_amt;
    // 주문가능금액
    let able_amt = 0;

    if (user.cont_gb === "01") {
      // 여신(특약)
      able_amt = user.han_amt - used_amt;
    } else if (user.cont_gb === "02") {
      // 선입금
      able_amt = used_amt < 0 ? Math.abs(used_amt) : 0;
    } else if (user.cont_gb === "03" || user.cont_gb === "04") {
      // 위탁 / PG사결제
      able_amt = 0;
    }

    setAmt({ ...amt, usedAmt: used_amt, ableAmt: able_amt });
  }, [user]);

  // 통합검색
  const handleTotalSearchButtonClick = useCallback(() => {
    if (searchKeyword.replace(/\s/g, "").length !== 0) {
      totalSearchBookMutation(searchKeyword, 0, "", "");
    }
  }, [searchKeyword]);

  // 통합검색 엔터키 이벤트
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleTotalSearchButtonClick();
    }
  };

  // 검색결과가 나올 시
  useEffect(() => {
    if (totalSearchData) {
      const searchInfo = {
        searchKeyword: searchKeyword,
        totalSearchData: totalSearchData?.data?.dataList,
        totalSearchedCount: totalSearchData?.data?.totalSearchedCount,
      };

      navigate("/order/total-search", { state: searchInfo });
      setSearchKeyword("");
    }
  }, [totalSearchData]);

  // 입점몰 관리자 페이지 이동
  const handelAdminPageLink = useCallback(async () => {
    await preMutation();

    deleteCookie("scm_store_rt");

    window.open(
      user.mallAdmSignInUrl +
        "/?userId=" +
        user.meclass_user_id +
        "&accessKey=" +
        getCookie("scm_store_at"),
    );
  }, []);

  return isHeaderNeeded && !isError ? (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="header-left-container">
          <ImageContainer
            src={KPCLogo}
            height={60}
            width={187}
            className="header-logo"
            onClick={handleLogoButtonClick}
          />
          <div className="total-search-container">
            <TextField
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value as string)}
              className="input-field"
              placeholder={"검색어를 입력하세요"}
              onKeyDown={handleKeyPress}
            />
            <Button
              className="search-button"
              variant="contained"
              onClick={handleTotalSearchButtonClick}
            >
              통합검색
            </Button>
          </div>
        </div>
        <div className="header-right-container">
          {isSignedIn && (
            <div className="store-info-container">
              {/* <SelectComponent /> */}
              <Button
                variant="text"
                className="header-button"
                onClick={() => navigate("/my-page")}
                title={user.userName ? `${user.userName}` : ""}
              >
                <div className="user-name-text">
                  {user.userName ? `${user.userName}` : ""}
                </div>
              </Button>
              {user.cont_gb !== "04" && (
                <>
                  <div className="menu-line">|</div>
                  <div className="credit-balance-text">
                    {user.cont_gbnm +
                      "한도금액 " +
                      formatNumberWithComma(user.han_amt) +
                      "원 / " +
                      "잔액 " +
                      formatNumberWithComma(amt.usedAmt) +
                      "원 / " +
                      "주문가능금액 " +
                      formatNumberWithComma(amt.ableAmt) +
                      "원 / " +
                      "임시한도금액 " +
                      formatNumberWithComma(user.banphan_amt) +
                      "원"}
                  </div>
                </>
              )}
              {user.meclass_gb !== "D" && (
                <>
                  <div className="menu-line">|</div>
                  <Button
                    variant="text"
                    className="header-button cart-button"
                    onClick={() => navigate("/order/cart-view")}
                  >
                    <img src={cartIcon} alt="" className="cart-icon" />
                    <div className="cart-text">장바구니</div>
                  </Button>
                </>
              )}
              <div className="menu-line">|</div>
            </div>
          )}
          <SignButton />
        </div>
      </div>
      <div
        className={menuWrapperClassName}
        onMouseEnter={() => setMenuWrapperClassName("menu-wrapper hover")}
        onMouseLeave={() => setMenuWrapperClassName("menu-wrapper")}
      >
        <div className="menu-container">
          {menuData.map((menu) => {
            const { title, childMenus, url } = menu;
            const selectedRoute = routes.find(
              (route) => location.pathname.split("?")[0] === route.path,
            );
            const selectedTitle = selectedRoute?.title ?? "";
            let isSelectedTitle = false;
            if (title === selectedTitle) {
              isSelectedTitle = true;
            } else {
              childMenus.forEach((childMenu) => {
                if (childMenu.title === selectedTitle) {
                  isSelectedTitle = true;
                }
              });
            }

            if (user.meclass_gb === "D" && menu.title === "도서주문") {
              return;
            } else {
              return (
                <div key={title} className="parent-menu-item">
                  <Link to={url ? url : "#"} className="parent-menu">
                    {title}
                  </Link>
                  <div
                    className={`indicator ${isSelectedTitle ? "active" : ""}`}
                  ></div>
                </div>
              );
            }
          })}
        </div>
        <div className="child-menu-wrapper">
          {menuData.map((menu) => {
            const { title, childMenus: _childMenus } = menu;

            const childMenus: ChildMenu[] = _childMenus;

            if (user.meclass_gb === "D" && menu.title === "도서주문") {
              return;
            } else {
              return (
                <div key={`${title}-child`} className="child-menu-container">
                  {childMenus.map((childMenu) => {
                    const url =
                      routes.find((route) => route.title === childMenu.title)
                        ?.path ?? "/";

                    return (
                      <div key={childMenu.title} className="child-menu">
                        <Link
                          to={url}
                          className="child-menu-link"
                          onClick={() =>
                            setMenuWrapperClassName("menu-wrapper")
                          }
                        >
                          {childMenu.title}
                        </Link>
                      </div>
                    );
                    // }
                  })}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  ) : null;
};

export default Header;
