import React from "react";
import Copyright from "./Copyright";

const Footer = () => {
  return (
    <div className="footer-container">
      <Copyright />
    </div>
  );
};

export default React.memo(Footer, () => true);
