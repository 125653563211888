import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: `"NanumBarunGothic", sans-serif`,
    fontSize: 13,
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        sx: {
          borderRadius: 0,
          fontWeight: 200,
        },
        disableInjectingGlobalStyles: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        sx: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        sx: {
          borderRadius: 0,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          borderRadius: 0,
          fontWeight: 200,
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          fontWeight: 200,
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        sx: {
          marginTop: -2,
        },
      },
    },
  },
});
