import type { URLPaths } from "types/urls";
import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

/* 
따로 useNavigation hook을 만들어서 사용하는 이유는
navigate 가능한 URL을 route 가능한 URL로 제한하고,
자동완성 기능을 이용함으로써 개발의 편의성과 개발자의 휴먼에러를 방지하기 위함이다.
*/
const useNavigation = () => {
  const _navigate = useNavigate();

  const navigate = useCallback((url: URLPaths, options?: NavigateOptions) => {
    if (options) {
      _navigate(url, options);
    } else {
      _navigate(url);
    }
  }, []);

  const goto = useCallback((num: number) => {
    _navigate(num);
  }, []);

  return { navigate, goto };
};

export default useNavigation;
