import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getBookInfoInBM,
  getBookInfoInWMS,
  getBookStatus,
  getClassNmList,
  getListRecommendMenu,
  getNewBookList,
  getRecommendBookList,
  getRecommendYearList,
  getRegYearList,
  getReturnBookInfoInWMS,
  getTotalSearchBooks,
  submitBookStatus,
  submitNewBook,
  _getBook,
} from "api/bookApi";
import queryKey from "common/constants/queryKey";
import { useMessagebar } from "common/hooks";
import useBranchSelection from "common/hooks/useBranchSelection";
import usePreMutation from "common/hooks/usePreMutation";
import { useCallback, useEffect, useState } from "react";
import { APIErrorResponse } from "types/api";

/* 
lazy하게 쿼리를 사용하기 위해서는 다음과 같이 작성한다.
fetchData를 통해 쿼리를 enable시켜서 데이터를 가져오게 하고,
onSuccess와 onError에서 쿼리를 disable시켜줌으로써
사용자가 조회 버튼을 누를 때만 조회되도록 한다.
*/
export const useBookStatusQuery = (params: object) => {
  const [loading, setLoading] = useState(false);

  const query = useQuery([queryKey.BOOK, params], () => getBookStatus(params), {
    enabled: loading,
    onSuccess: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const fetchData = useCallback(() => {
    setLoading(true);
  }, []);

  return { ...query, fetchData };
};

export const useBookStatusMutation = () => {
  const { enqueue } = useMessagebar();
  const mutation = useMutation(submitBookStatus, {
    onError: () => {
      enqueue.error("에러가 발생했어요. 계속되면 관리자에게 문의해주세요.");
    },
    onSuccess: () => {
      enqueue.success("저장되었습니다.");
    },
  });
  return { ...mutation };
};

export const useBookQuery = (keyword: string) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (keyword !== "") {
      setLoading(true);
    }
  }, [keyword]);

  const query = useQuery([queryKey.BOOK, keyword], () => _getBook(keyword), {
    enabled: loading,
    onSuccess: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (query.data) {
      setLoading(false);
    }
  }, [query.data]);
  return { ...query, loading };
};

export const useSubmitNewBookMutation = () => {
  const { enqueue } = useMessagebar();
  const mutation = useMutation(submitNewBook, {
    onSuccess: () => {
      enqueue.success("업로드되었습니다.");
    },
    onError: () => {
      enqueue.error("에러가 발생했어요. 계속되면 관리자에게 문의해주세요.");
    },
  });

  return { ...mutation };
};

// 통합검색 (request: ISBN, 도서명, 출판사, 저자 등 / response: ISBN, 도서명, 출판사, 저자 등)
export const useTotalSearchMutation = () => {
  const { selectedCode } = useBranchSelection();
  const mutation = useMutation(getTotalSearchBooks);
  const { mutationWrapper } = usePreMutation();

  const handleMutation = useCallback(
    (
      searchKeyword: string,
      start: number,
      reSearchKeyword: string,
      sort?: string,
    ) => {
      if (searchKeyword !== "") {
        //
        let requestBody = {
          metax_no: selectedCode,
          start,
        };

        let q = "";
        let fq = "";

        const qList = searchKeyword.split(" ");
        const fqList = reSearchKeyword.split(" ");

        // 검색어 리스트 길이에 따라 q, fq 지정하는 로직
        if (qList.length === 1) {
          // 검색어 리스트 길이가 1인 경우(검색어가 띄어쓰기 없이 1개인 경우)
          q = qList[0];
        } else {
          // 검색어 리스트의 길이가 1 이상인 경우(검색어가 여러개인 경우)
          qList.map((datum, i) => {
            if (i === 0) {
              q = datum;
            } else if (i === 1) {
              fq = datum;
            } else {
              fq = fq + " " + datum;
            }
          });
        }

        if (reSearchKeyword) {
          fqList.map((datum, i) => {
            if (fq === "") {
              fq = datum;
            } else {
              fq = fq + " " + datum;
            }
          });
        }

        requestBody = Object.assign(requestBody, { q });
        if (fq !== "") {
          requestBody = Object.assign(requestBody, { fq });
        }
        if (sort !== "") {
          requestBody = Object.assign(requestBody, { sort });
        }

        mutationWrapper(() => {
          mutation.mutate(requestBody);
        });
      }
    },
    [],
  );

  return { handleMutation, ...mutation };
};

// 도서마스터에서 도서 조회(request: ISBN, 도서명 / response: ISBN, 도서명, 출판사 등)
export const useGetBookInfoInBMMutation = () => {
  const mutation = useMutation(getBookInfoInBM);

  return { ...mutation };
};

// WMS에서 도서 정보 조회(request: 도서코드 / response: 반품가능수량, 도서상태, 출고율 등)
export const useGetBookInfoInWMSMutation = () => {
  const mutation = useMutation(getBookInfoInWMS);

  return { ...mutation };
};

// WMS에서 반품 도서 정보 조회(request: 서점코드, 도서코드 / response: 반품가능여부구분, 가능수량, 반품율 등)
export const useGetReturnBookInfoInWMSMutation = () => {
  const mutation = useMutation(getReturnBookInfoInWMS);

  return { ...mutation };
};

export const useGetListRecommendMenuQuery = () => {
  const query = useQuery(["foo"], getListRecommendMenu, {
    suspense: true,
    useErrorBoundary: false,
  });

  return { ...query };
};

// 신간도서 연도 리스트 조회
// export const useGetRegYearListMutation = () => {
//   const [errorMessage, setErrorMessage] = useState("");
//   const mutation = useMutation(getRegYearList, {
//     onError: (error: APIErrorResponse | any) => {
//       if (error.code === "ERR_NETWORK") {
//         setErrorMessage("서버 오류로 서비스를 사용할 수 없습니다.");
//       } else {
//         if (error.status === 500) {
//           setErrorMessage("서버 오류입니다.");
//         } else {
//           setErrorMessage(error?.data?.resultMsg);
//         }
//       }
//     },
//   });
//   const { preMutation } = usePreMutation();

//   const handleMutation = useCallback(async () => {
//     await preMutation();
//     await mutation.mutateAsync();
//   }, [mutation]);

//   return { handleMutation, ...mutation };
// };

// 신간도서 분류 옵션 리스트 조회
export const useGetClassListMutation = () => {
  const mutation = useMutation(getClassNmList);
  const { preMutation } = usePreMutation();

  const handleMutation = useCallback(async () => {
    await preMutation();
    await mutation.mutateAsync();
  }, []);

  return { handleMutation, ...mutation };
};

// 신간도서 목록 조회(request: 신간구분, 년,월,주차정보 / response: ISBN, 도서명, 출판사 등)
export const useGetNewBookListMutation = (
  searchParam: Record<string, unknown>,
) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { selectedCode } = useBranchSelection();
  const { preMutation } = usePreMutation();
  const mutation = useMutation(getNewBookList, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      if (error.code === "ERR_NETWORK") {
        setErrorMessage("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          setErrorMessage("서버 오류입니다.");
        } else {
          setErrorMessage(error?.data?.resultMsg);
        }
      }
    },
  });

  const handleMutation = useCallback(async () => {
    const {
      searchType,
      st_date,
      ed_date,
      classNm,
      bookTitle,
      // regYear,
      // regMonth,
      // regWeek,
    } = searchParam;

    let requestBody = {
      metax_no: selectedCode,
      searchType: searchType,
      st_date,
      ed_date,
      // regYear: regYear,
    };
    // if (regMonth && regMonth !== "all")
    //   requestBody = Object.assign(requestBody, { regMonth });
    // if (regWeek && regWeek !== "all")
    //   requestBody = Object.assign(requestBody, { regWeek });
    if (classNm && classNm !== "all")
      requestBody = Object.assign(requestBody, { classNm });
    if (bookTitle && bookTitle !== "")
      requestBody = Object.assign(requestBody, { bookTitle });

    // 구분이 신간이고, 도서명이 존재하는 경우 아래 로직에 따라 검색어 변수를 requestBody에 포함
    if (searchType === "openDate" && bookTitle && bookTitle !== "") {
      const searchKeyword = bookTitle as string;

      let q = "";
      let fq = "";

      const qList = searchKeyword.split(" ");
      // const fqList = bookTitle.split(" ");

      // 검색어 리스트 길이에 따라 q, fq 지정하는 로직
      if (qList.length === 1) {
        // 검색어 리스트 길이가 1인 경우(검색어가 띄어쓰기 없이 1개인 경우)
        q = qList[0];
      } else {
        // 검색어 리스트의 길이가 1 이상인 경우(검색어가 여러개인 경우)
        qList.map((datum, i) => {
          if (i === 0) {
            q = datum;
          } else if (i === 1) {
            fq = datum;
          } else {
            fq = fq + " " + datum;
          }
        });
      }

      requestBody = Object.assign(requestBody, { q });
      if (fq !== "") {
        requestBody = Object.assign(requestBody, { fq });
      }
    }

    await preMutation();
    await mutation.mutateAsync(requestBody);
  }, [searchParam]);

  return { handleMutation, ...mutation };
};

// 추천도서 연도 리스트 조회
export const useGetRecommendYearListMutation = () => {
  const query = useQuery(["yearList"], getRecommendYearList, {
    suspense: true,
    useErrorBoundary: false,
  });

  return { ...query };
};

// 추천도서 리스트 조회 (request: 추천도서구분, 년 / response: ISBN, 도서명, 출판사 등)
export const useGetRecommendListMutation = (
  searchParam: Record<string, unknown>,
) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { selectedCode } = useBranchSelection();
  const { preMutation } = usePreMutation();
  const mutation = useMutation(getRecommendBookList, {
    useErrorBoundary: false,
    onError: (error: APIErrorResponse | any) => {
      if (error.code === "ERR_NETWORK") {
        setErrorMessage("서버 오류로 서비스를 사용할 수 없습니다.");
      } else {
        if (error.status === 500) {
          setErrorMessage("서버 오류입니다.");
        } else {
          setErrorMessage(error?.data?.resultMsg);
        }
      }
    },
  });

  const handleMutation = useCallback(async () => {
    const { catCd, year } = searchParam;

    let requestBody = {
      metax_no: selectedCode,
      catCd: catCd,
    };
    if (year && year !== "all")
      requestBody = Object.assign(requestBody, { year });

    await preMutation();
    await mutation.mutateAsync(requestBody);
  }, [searchParam]);

  return { handleMutation, ...mutation };
};
