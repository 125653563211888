import type { CookieKeyType } from "types/cookie";

export function getCookie(cookie_name: CookieKeyType) {
  let x, y;
  const val = document.cookie.split(";");
  for (let i = 0; i < val.length; i++) {
    [x, y] = val[i].split("=");
    x = x.replace(/^\s+|\s+$/g, "");
    if (x == cookie_name) {
      return decodeURI(y);
    }
  }
}

export function setCookie(
  cookie_name: CookieKeyType,
  value: string,
  miuntes?: number,
) {
  const exdate = new Date();
  exdate.setMinutes(exdate.getMinutes() + (miuntes ?? 0));
  const cookie_value =
    decodeURI(value) +
    ";path=/" +
    (miuntes == null ? "" : ";expires=" + exdate.toUTCString());
  document.cookie = cookie_name + "=" + cookie_value;
}

export function deleteCookie(cookie_name: CookieKeyType) {
  const exdate = new Date(0);
  const value = "";
  const cookie_value = decodeURI(value) + ";expires=" + exdate.toUTCString();
  document.cookie = cookie_name + "=" + cookie_value;
}

export function base64Decode(base64String: string) {
  return JSON.parse(atob(base64String));
}
