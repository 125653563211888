import { useMutation, useQuery } from "@tanstack/react-query";
import {
  changePassword,
  checkRefreshToken,
  getMenu,
  getUserInfo,
} from "api/userApi";
import queryKey from "common/constants/queryKey";
import { useUser } from "common/hooks";
import type { APIResponseData } from "types/api";
import type { UserResponse } from "types/user";

export const useMenuQuery = () => {
  const { isSignedIn } = useUser();
  const query = useQuery([queryKey.MENU], getMenu, {
    enabled: isSignedIn,
  });

  return { ...query };
};

export const useUserInfoQuery = () => {
  const { setUser } = useUser();
  const query = useQuery([queryKey.USER], getUserInfo, {
    onSuccess: (res: APIResponseData<UserResponse>) => {
      if (res.data) {
        const {
          MOD_SECRET_KEY,
          shipFreeAmt,
          cont_gbnm,
          vbankLimitAmt,
          USER_ID,
          NICK_NAME,
          jptax_no,
          shipAmt,
          banphan_amt,
          misu_amt,
          jpcust_nm,
          cardLimitAmt,
          han_amt,
          juing_amt,
          accounts,
          cont_gb,
          mid,
          merchantKey,
          meclass_gb,
          meclass_user_id,
          mallAdmSignInUrl,
          initSecretKeyYn,
          cust_gb,
          besong_gb,
        } = res.data;

        setUser({
          userName: NICK_NAME,
          modSecretKey: MOD_SECRET_KEY,
          banphan_amt,
          han_amt,
          misu_amt,
          juing_amt,
          cont_gb,
          cont_gbnm,
          branchCode: USER_ID,
          headCode: jptax_no,
          shipFreeAmt,
          vbankLimitAmt,
          shipAmt,
          cardLimitAmt,
          accounts,
          jpcust_nm,
          mid,
          merchantKey,
          meclass_gb,
          meclass_user_id,
          mallAdmSignInUrl,
          initSecretKeyYn,
          cust_gb,
          besong_gb,
        });
      }
    },
    useErrorBoundary: false,
    suspense: true,
  });
  return { ...query };
};

export const useChangePasswordQuery = () => {
  const mutation = useMutation(changePassword, {
    useErrorBoundary: false,
  });
  return { ...mutation };
};

// 리프레시 토큰이 있는지 체크
export const useCheckRefreshTokenMutation = () => {
  const mutation = useMutation(checkRefreshToken);

  return { ...mutation };
};
