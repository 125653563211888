import { useEffect } from "react";
import { setLoadingStore } from "stores/loadingStore";

const useLoading = (isLoading: boolean) => {
  useEffect(() => {
    setLoadingStore(isLoading);
    return () => {
      setLoadingStore(false);
    };
  }, [isLoading]);
};

export default useLoading;
