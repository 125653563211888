import { useSnackbar } from "notistack";
import { useCallback } from "react";

const useMessagebar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueue = {
    info: useCallback((message: string, anchorOrigin?: any) => {
      enqueueSnackbar(message, {
        variant: "info",
        anchorOrigin,
      });
    }, []),
    success: useCallback((message: string, anchorOrigin?: any) => {
      enqueueSnackbar(message, {
        variant: "success",
        anchorOrigin,
      });
    }, []),
    error: useCallback((message: string, anchorOrigin?: any) => {
      enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin,
      });
    }, []),
    default: useCallback((message: string, anchorOrigin?: any) => {
      enqueueSnackbar(message, {
        variant: "default",
        anchorOrigin,
      });
    }, []),
    warning: useCallback((message: string, anchorOrigin?: any) => {
      enqueueSnackbar(message, {
        variant: "warning",
        anchorOrigin,
      });
    }, []),
  };

  return { enqueue };
};

export default useMessagebar;
