import { delay } from "common/utils";
import { axiosInstance } from "config/axiosConfig";

export async function getBookStatus(params: object) {
  const response = await axiosInstance.get("/book-status", {
    params: params,
  });

  return response.data;
}

export async function submitBookStatus(bookStatusRequest: object) {
  const response = await axiosInstance.post(
    "/update-sold-out",
    bookStatusRequest,
  );

  return response.data;
}

// 통합검색
export async function getTotalSearchBooks(param: Record<string, unknown>) {
  const response = await axiosInstance.post("/book/getTotalSearchBooks", param);
  return response.data;
}

// 도서마스터에서 도서 조회
export async function getBookInfoInBM(_param: Record<string, unknown>) {
  let param = _param;
  let q = "";
  let fq = "";

  const qList = (param?.q as string).split(" ");

  // 검색어 리스트 길이에 따라 q, fq 지정하는 로직
  if (qList.length === 1) {
    // 검색어 리스트 길이가 1인 경우(검색어가 띄어쓰기 없이 1개인 경우)
    q = qList[0];
  } else {
    // 검색어 리스트의 길이가 1 이상인 경우(검색어가 여러개인 경우)
    qList.map((datum, i) => {
      if (i === 0) {
        q = datum;
      } else if (i === 1) {
        fq = datum;
      } else {
        fq = fq + " " + datum;
      }
    });
  }

  param = Object.assign(param, { q });
  if (fq !== "") {
    param = Object.assign(param, { fq });
  }

  const response = await axiosInstance.post("/book/getSolrStoreBooks", param);

  return response.data;
}

// WMS에서 도서 정보 조회
export async function getBookInfoInWMS(param: Record<string, unknown>) {
  const response = await axiosInstance.post(
    "/api/outbound/callScmS002BOOK",
    param,
  );
  return response.data;
}

// WMS에서 반품 도서 정보 조회
export async function getReturnBookInfoInWMS(param: Record<string, unknown>) {
  const response = await axiosInstance.post(
    "/api/outbound/callScmS00304A",
    param,
  );
  return response.data;
}

// 신간도서 연도 목록 조회
export async function getRegYearList() {
  const response = await axiosInstance.post("/book/getNewBookRegYearList");
  return response.data;
}

// 신간도서 분야 목록 조회
export async function getClassNmList() {
  const response = await axiosInstance.post("/book/getNewBookClassNmList");
  return response.data;
}

// 신간도서 목록 조회
export async function getNewBookList(param: Record<string, unknown>) {
  const response = await axiosInstance.post("/book/getNewBookList", param);
  return response.data;
}

export async function _getBook(keyword: string) {
  // const response = await axiosInstance.get("/book", {
  //   params: {
  //     keyword: keyword,
  //   },
  // });
  await delay(500);
  const responseArr = [
    {
      bookId: 1001,
      bookName: "화산에서 살아남기",
      author: "홍길동",
      publisher: "가나출판사",
      isbn: "972-2-75-962029-2",
      price: 20000,
    },
    {
      bookId: 1002,
      bookName: "바다에서 살아남기",
      author: "홍길동",
      publisher: "가나출판사",
      isbn: "972-2-75-962030-3",
      price: 20000,
    },
    {
      bookId: 1003,
      bookName: "동굴에서 살아남기",
      author: "홍길동",
      publisher: "가나출판사",
      isbn: "972-2-75-962031-4",
      price: 20000,
    },
    {
      bookId: 1004,
      bookName: "고슴도치야, 이리 와.",
      author: "김작가",
      publisher: "다라출판사",
      isbn: "972-2-75-962032-5",
      price: 12000,
    },
    {
      bookId: 1005,
      bookName: "고슴도치야, 저리 가.",
      author: "김작가",
      publisher: "다라출판사",
      isbn: "972-2-75-962033-6",
      price: 12000,
    },
  ].filter(
    (book) => book.bookName.includes(keyword) || book.isbn.includes(keyword),
  );
  /* 
  가상의 검색을 구현, 백엔드에서 추후 lucene을 이용한 검색을 구현할 것이므로
  키워드를 전달해주는 것 이상 프론트에서 필터링을 구현할 필요는 없음.
  단, 키워드에 따른 쿼리 결과 자체는 프론트에서 캐싱되어서 
  한 번 사용된 키워드에 대해서는 5분 이내에 재요청을 보내지 않을 것을 보장함.
  */
  return Promise.resolve(responseArr);
}

export async function submitNewBook(formData: FormData) {
  const response = await axiosInstance.post("/new-book", formData);
  return response.data;
}

// 추천도서 메뉴 조회
export async function getListRecommendMenu() {
  const response = await axiosInstance.post("/book/getListRecommendMenu");
  return response.data;
}

// 추천도서 연도 목록 조회
export async function getRecommendYearList() {
  const response = await axiosInstance.post("/book/getRecommendBookYearList");
  return response.data;
}

// 추천도서 목록 조회
export async function getRecommendBookList(param: Record<string, unknown>) {
  const response = await axiosInstance.post(
    "/book/getListRecommendBookList",
    param,
  );
  return response.data;
}
