import { useCallback } from "react";
import {
  useDisplayModalStore,
  setDisplayModalStore,
  initDisplayModalState,
} from "stores/displayModalStore";

const useModal = () => {
  const { open, ModalComponent, preventBackgroundClickClose } =
    useDisplayModalStore();
  const modal = {
    open: useCallback((modal: JSX.Element, backgroundClickClose = false) => {
      setDisplayModalStore((prev) => ({
        ...prev,
        ModalComponent: modal,
        open: true,
        preventBackgroundClickClose: backgroundClickClose,
      }));
    }, []),
    close: useCallback(() => {
      if (preventBackgroundClickClose === false) {
        setDisplayModalStore(initDisplayModalState());
      }
    }, [preventBackgroundClickClose]),
  };

  return {
    open,
    ModalComponent,
    modal,
    preventBackgroundClickClose,
  };
};

export default useModal;
