const queryKey = {
  SIGN_IN: "SIGN_IN",
  TEST: "TEST",
  USER: "USER",
  SESSION: "SESSION",
  LAZY_TEST: "LAZY_TEST",
  MENU: "MENU",
  BOARD: "BOARD",
  BOOK: "BOOK",
  SOLD_OUT_BOOK: "SOLD_OUT_BOOK",
  NOTICE: "NOTICE",
  ORDER_STATUS: "ORDER_STATUS",
  DEAL: "DEAL",
  ONE_TO_ONE: "ONE_TO_ONE",
  DELIVERY: "DELIVERY",
  CART: "CART",
  ADDRESS: "ADDRESS",
};
export default queryKey;

export const noticeQueryKey = {
  list: (rows: number | string, start: number | string | null) => [
    queryKey.NOTICE,
    `rows=${rows}`,
    `start=${start}`,
  ],
  detail: (ntcSeq: number | string | null) => [queryKey.NOTICE, ntcSeq],
  all: () => [queryKey.NOTICE],
};

export const oneToOneQueryKey = {
  list: (rows: number | string, start: number | string | null) => [
    queryKey.ONE_TO_ONE,
    `rows=${rows}`,
    `start=${start}`,
  ],
  all: () => [queryKey.ONE_TO_ONE],
};

export const deliveryQueryKey = {
  list: (
    //metax_no: number | string,
    st_date: number | string,
    ed_date: number | string,
    deli_num: number | string | null,
    deli_name: number | string | null,
  ) => [
    queryKey.DELIVERY,
    //`metax_no=${metax_no}`,
    `st_date=${st_date}`,
    `ed_date=${ed_date}`,
    `deli_num=${deli_num}`,
    `deli_name=${deli_name}`,
  ],
  detail: (
    //metax_no: number | string,
    deli_num: number | string,
  ) => [
    queryKey.DELIVERY,
    //`metax_no=${metax_no}`,
    `deli_num=${deli_num}`,
  ],
  all: () => [queryKey.DELIVERY],
};

export const cartQueryKey = {
  list: () => [queryKey.CART],
  detail: () => [queryKey.CART],
  all: () => [queryKey.CART],
};

export const addressQueryKey = {
  list: (ordNm: string | null) => [queryKey.ADDRESS, `ordNm=${ordNm}`],
  detail: (addressNo: number | string | null) => [
    queryKey.ADDRESS,
    `addressNo=${addressNo}`,
  ],
  all: () => [queryKey.ADDRESS],
};
