import { Typography } from "@mui/material";
import React from "react";

const Copyright = () => {
  return (
    <Typography align="center">
      {`COPYRIGHT ${new Date().getFullYear()}. 한국출판협동조합 ALL RIGHTS RESERVED.`}
    </Typography>
  );
};

export default React.memo(Copyright, () => true);
